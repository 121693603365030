var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { staticClass: "py-12" }, [
    _c("h1", { staticClass: "mb-5 text-center" }, [
      _vm._v("MyEtherWallet security policy"),
    ]),
    _c("p", [
      _vm._v(
        " MyEtherWallet (MEW) looks forward to working with the security community to identify vulnerabilities in order to keep our users and MEW safe. "
      ),
    ]),
    _c("p", [
      _vm._v(
        " MEW is passionate about providing a seamless and secure experience to our community. We are devoted to protecting our users private information and providing them an opportunity to interact with the Ethereum blockchain in the safest manner possible. Security is our first priority and obligation and we always appreciate the valuable contributions! If you have any information regarding security vulnerabilities on the "
      ),
      _c("strong", [
        _vm._v(
          " MyEtherWallet website, MEW wallet App, MEWconnect App and MEW CX "
        ),
      ]),
      _vm._v(" report it to "),
      _c("a", { attrs: { href: "mailto:bugbounty@myetherwallet.com" } }, [
        _vm._v(" bugbounty@myetherwallet.com "),
      ]),
      _vm._v(" according to our guidelines (jump to reporting rules)! "),
    ]),
    _c("p", [
      _vm._v(
        " Any vulnerabilities submitted under this policy will be used for the purposes of improving MyEtherWallet security as well as the user experience. We aim to establish a positive feedback between MEW and researchers that will contribute to this program - we appreciate your patience as we strive to perfect this process. "
      ),
    ]),
    _c("div", { staticClass: "mb-3" }, [
      _c("div", { staticClass: "mb-2" }, [
        _vm._v(
          " This program is aimed in finding security vulnerabilities in "
        ),
      ]),
      _c("ul", { staticClass: "pl-5" }, [
        _c("li", [_vm._v("MEW wallet App (Beta version Included)")]),
        _c("li", [_vm._v("MEWconnect App (Beta version Included)")]),
        _c("li", [_vm._v("Current live version of MyEtherWallet . com")]),
        _c("li", [_vm._v("MEW CX")]),
      ]),
    ]),
    _c("p", [
      _c("strong", [
        _vm._v(
          " Please review, understand, and agree to the following terms and conditions before conducting any testing for MEW and before submitting a report. "
        ),
      ]),
    ]),
    _c("p", [_vm._v("Thank you.")]),
    _c("table", { staticClass: "main-table my-12" }, [
      _c("tbody", [
        _c("tr", [
          _c("td", { staticClass: "pa-5" }, [_vm._v("Scope")]),
          _c("td", { staticClass: "pa-5" }, [
            _c("div", [
              _c("strong", [_vm._v("MyEtherWallet.com")]),
              _c("div", { staticClass: "pl-5" }, [
                _vm._v(" Code base can be found: "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://github.com/MyEtherWallet/MyEtherWallet",
                      target: "_blank",
                    },
                  },
                  [_vm._v(" https://github.com/MyEtherWallet/MyEtherWallet ")]
                ),
              ]),
            ]),
            _c("div", { staticClass: "mt-3" }, [
              _c("strong", [_vm._v("MEW wallet App")]),
              _c("div", { staticClass: "pl-5" }, [
                _vm._v(" Code base can be found: "),
                _c("ul", { staticClass: "px-5" }, [
                  _c("li", [
                    _vm._v(" iOS: "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://apps.apple.com/app/id1464614025",
                          target: "_blank",
                        },
                      },
                      [_vm._v(" https://apps.apple.com/app/id1464614025 ")]
                    ),
                  ]),
                  _c("li", [
                    _vm._v(" Android: "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://play.google.com/store/apps/details?id=com.myetherwallet.mewwallet",
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          "https://play.google.com/store/apps/details?id=com.myetherwallet.mewwallet"
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "mt-3" }, [
              _c("strong", [_vm._v("MEWconnect App")]),
              _c("div", { staticClass: "pl-5" }, [
                _vm._v(" Code base Android: "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://github.com/MyEtherWallet/MEWconnect-Android",
                      target: "_blank",
                    },
                  },
                  [
                    _vm._v(
                      " https://github.com/MyEtherWallet/MEWconnect-Android "
                    ),
                  ]
                ),
                _c("br"),
                _vm._v(" Code base iOS: "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://github.com/MyEtherWallet/MEWconnect-iOS",
                      target: "_blank",
                    },
                  },
                  [_vm._v(" https://github.com/MyEtherWallet/MEWconnect-iOS ")]
                ),
              ]),
            ]),
            _c("div", { staticClass: "mt-3" }, [
              _c("strong", [_vm._v("Enkrypt")]),
              _c("div", { staticClass: "pl-5" }, [
                _vm._v(" Code base can be found: "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://github.com/enkryptcom/enKrypt",
                      target: "_blank",
                    },
                  },
                  [_vm._v(" https://github.com/enkryptcom/enKrypt ")]
                ),
                _c("br"),
                _vm._v(" Website: "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.enkrypt.com",
                      target: "_blank",
                    },
                  },
                  [_vm._v(" Enkrypt.com ")]
                ),
              ]),
            ]),
          ]),
        ]),
        _c("tr", [
          _c("td", { staticClass: "pa-5" }, [_vm._v("Rewards")]),
          _c("td", { staticClass: "pa-5" }, [
            _c("div", [
              _vm._v(
                " Our rewards are based on the severity per CVSS (the Common Vulnerability Scoring Standard "
              ),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://en.wikipedia.org/wiki/Common_Vulnerability_Scoring_System",
                    target: "_blank",
                  },
                },
                [
                  _vm._v(
                    " https://en.wikipedia.org/wiki/Common_Vulnerability_Scoring_System "
                  ),
                ]
              ),
              _vm._v(
                " ). Please note these are general guidelines, and that reward decisions are up to the discretion of MyEtherWallet. "
              ),
              _c("strong", [
                _vm._v(
                  " Each bounty reward will be paid in ETH with equivalent USD value, at the time of transfer: "
                ),
              ]),
            ]),
            _c("table", { staticClass: "sub-table mt-3 mb-1" }, [
              _c("tr", [
                _c("td", { staticClass: "py-1 px-2" }, [
                  _c("strong", [_vm._v("Critical (9.0 - 10.0)")]),
                ]),
                _c("td", { staticClass: "py-1 px-2" }, [
                  _c("strong", [_vm._v("High (7.0 - 8.9)")]),
                ]),
                _c("td", { staticClass: "py-1 px-2" }, [
                  _c("strong", [_vm._v("Medium (4.0 - 6.9)")]),
                ]),
                _c("td", { staticClass: "py-1 px-2" }, [
                  _c("strong", [_vm._v("Low (0.1 - 3.9)")]),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "py-1 px-2" }, [_vm._v("$2,000")]),
                _c("td", { staticClass: "py-1 px-2" }, [_vm._v("$1,000")]),
                _c("td", { staticClass: "py-1 px-2" }, [_vm._v("$500")]),
                _c("td", { staticClass: "py-1 px-2" }, [_vm._v("$250")]),
              ]),
            ]),
          ]),
        ]),
        _c("tr", [
          _c("td", { staticClass: "pa-5" }, [_vm._v("SLA")]),
          _c("td", { staticClass: "pa-5" }, [
            _c("div", [
              _vm._v(
                " MyEtherWallet will make a best effort to meet the following SLAs for researches participating in our bug bounty program: "
              ),
              _c("br"),
              _c("br"),
              _c("strong", [
                _vm._v(
                  "* Time to first response (from report submit) - 3 business days"
                ),
              ]),
              _c("br"),
              _c("strong", [
                _vm._v(
                  "* Time to triage (from report submit) - 5 business days"
                ),
              ]),
              _c("br"),
              _c("strong", [
                _vm._v("* Time to bounty (from triage) - 5 business days"),
              ]),
              _c("br"),
              _c("br"),
              _vm._v(
                " We take every report seriously and very much appreciate the efforts of researchers that contribute to this program. Please be patient as we’ll try to keep you informed about our progress throughout the process. "
              ),
            ]),
          ]),
        ]),
        _c("tr", [
          _c("td", { staticClass: "pa-5" }, [_vm._v("Disclosure Policy")]),
          _c("td", { staticClass: "pa-5" }, [
            _c("div", [
              _vm._v(
                " Please do not discuss this program or any vulnerabilities (even resolved ones) outside, without express consent from MyEtherWallet. "
              ),
            ]),
          ]),
        ]),
        _c("tr", [
          _c("td", { staticClass: "pa-5" }, [_vm._v("Reporting Rules")]),
          _c("td", { staticClass: "pa-5" }, [
            _c("div", { staticClass: "mb-3" }, [
              _vm._v(
                " Report eligible vulnerability to EMAIL Please provide detailed reports with reproducible steps. If the report is not detailed enough to reproduce the issue, the issue will not be eligible for a reward. "
              ),
            ]),
            _c("div", { staticClass: "mb-3" }, [
              _vm._v(
                " Submit one vulnerability per report, unless you need to chain vulnerabilities to provide impact. "
              ),
            ]),
            _c("div", { staticClass: "mb-3" }, [
              _vm._v(
                " When duplicates occur, we only award the first report that was received (provided that it can be fully reproduced). "
              ),
            ]),
            _c("div", { staticClass: "mb-3" }, [
              _vm._v(
                " Multiple vulnerabilities caused by one underlying issue will be awarded one bounty. "
              ),
            ]),
            _c("div", { staticClass: "mb-3" }, [
              _vm._v(
                " Social engineering (e.g. phishing, vishing, smishing) is prohibited. "
              ),
            ]),
            _c("div", [
              _vm._v(
                " Make a good faith effort to avoid privacy violations, destruction of data, and interruption or degradation of our service. "
              ),
              _c("strong", [
                _vm._v(
                  " Only interact with accounts you own or with explicit permission of the account holder. "
                ),
              ]),
            ]),
          ]),
        ]),
        _c("tr", [
          _c("td", { staticClass: "pa-5" }, [
            _vm._v("In Scope Vulnerabilities"),
          ]),
          _c("td", { staticClass: "pa-5" }, [
            _c("div", { staticClass: "mb-3" }, [
              _vm._v(
                " In general, any bug that can lead to loss of finances or leak of users’ personal data. "
              ),
            ]),
            _c("div", { staticClass: "mb-3" }, [
              _vm._v(
                " These includes bugs with sufficient severity and not limited to: "
              ),
            ]),
            _c("div", { staticClass: "mb-2" }, [
              _c("strong", [_vm._v("MyEtherWallet Website and MEW CX:")]),
            ]),
            _c("ul", { staticClass: "mb-3 px-5" }, [
              _c("li", [_vm._v("Clickjacking")]),
              _c("li", [_vm._v("XSS")]),
              _c("li", [_vm._v("Remote code execution")]),
              _c("li", [_vm._v("CSRF")]),
              _c("li", [
                _vm._v("Authentication bypass or privilege escalation"),
              ]),
              _c("li", [
                _vm._v("Accessing users private keys and personal data"),
              ]),
            ]),
            _c("div", { staticClass: "mb-2" }, [
              _c("strong", [_vm._v("MEW wallet and MEWconnect apps:")]),
            ]),
            _c("ul", { staticClass: "px-5" }, [
              _c("li", [_vm._v("Injection, Manipulation of account balance")]),
              _c("li", [
                _vm._v(
                  " Authentication bypasses, Loss of privileged information (passwords, private keys, etc.) "
                ),
              ]),
              _c("li", [_vm._v("Unauthorised Transaction signing")]),
              _c("li", [_vm._v("Unauthorised P2P connection")]),
              _c("li", [
                _vm._v(
                  " Modification of request received by the Mobile application on MCBeta.myetherwallet.com "
                ),
              ]),
            ]),
          ]),
        ]),
        _c("tr", [
          _c("td", { staticClass: "pa-5" }, [
            _vm._v("Out of scope vulnerabilities"),
          ]),
          _c("td", { staticClass: "pa-5" }, [
            _c("div", { staticClass: "mb-3" }, [
              _vm._v(
                " When reporting vulnerabilities, please consider (1) attack scenario / exploitability, and (2) security impact of the bug. The following issues are considered out of scope: "
              ),
            ]),
            _c("div", { staticClass: "mb-2" }, [
              _c("strong", [_vm._v("MyEtherWallet Website and MEW CX")]),
            ]),
            _c("ul", { staticClass: "px-5" }, [
              _c("li", [
                _vm._v("Clickjacking on pages with no sensitive information."),
              ]),
              _c("li", [_vm._v("Self-XSS")]),
              _c("li", [_vm._v("Logout CSRF")]),
              _c("li", [_vm._v("Lack of password length restrictions")]),
              _c("li", [
                _vm._v(
                  " Previously known vulnerable libraries without a working Proof of Concept. "
                ),
              ]),
              _c("li", [
                _vm._v(
                  " Comma Separated Values (CSV) injection without demonstrating a vulnerability. "
                ),
              ]),
              _c("li", [
                _vm._v(
                  " Content spoofing and text injection issues without showing an attack vector/without being able to modify HTML/CSS "
                ),
              ]),
              _c("li", [
                _vm._v(
                  " Merely showing that a page can be iFramed without finding a link on the page to be click-jacked. "
                ),
              ]),
              _c("li", [_vm._v("DNS vulnerabilities")]),
              _c("li", [_vm._v("Denial of service")]),
              _c("li", [_vm._v("Spamming")]),
              _c("li", [
                _vm._v(
                  " Vulnerabilities in third party applications which make use of the MEW’s API "
                ),
              ]),
              _c("li", [
                _vm._v(
                  " Vulnerabilities which involve privileged access (e.g. rooting a phone) to a victim's device(s) "
                ),
              ]),
              _c("li", [
                _vm._v(
                  " Reports from automated tools or scans (without accompanying demonstration of exploitability) "
                ),
              ]),
              _c("li", [_vm._v("Text-only injection in error pages")]),
              _c("li", [
                _vm._v(
                  " Any other service not directly hosted or controlled by MyEtherWallet.com. MEW will determine at its discretion whether a vulnerability is eligible for a reward and the amount of the award. "
                ),
              ]),
              _c("li", [_vm._v("WebRTC protocol and implementation.")]),
              _c("li", [_vm._v("Socket.io protocol")]),
            ]),
          ]),
        ]),
        _c("tr", [
          _c("td", { staticClass: "pa-5" }, [
            _vm._v("MEW wallet and MEWconnect Mobile App"),
          ]),
          _c("td", { staticClass: "pa-5" }, [
            _c("div", [
              _c("ul", { staticClass: "px-5" }, [
                _c("li", [
                  _vm._v("Software bugs that have no security impact."),
                ]),
                _c("li", [
                  _vm._v(
                    " Public User data, such as, public address, balances, transaction information etc. stored unencrypted on external storage and private directory. "
                  ),
                ]),
                _c("li", [
                  _vm._v(
                    " Runtime hacking exploits (exploits only possible in a jailbroken environment) "
                  ),
                ]),
                _c("li", [
                  _vm._v(
                    " Require physical connection to the device with developer-level debugging tool. "
                  ),
                ]),
                _c("li", [
                  _vm._v(
                    " Result in an application-level crash, or simply mention the possibility of MITM without an exploit. "
                  ),
                ]),
                _c("li", [
                  _vm._v(
                    " Reports based on information taken or obtained through illegal access and confidential information "
                  ),
                ]),
                _c("li", [
                  _vm._v(
                    " Vulnerabilities on sites hosted by third parties unless they lead to a vulnerability on the app. "
                  ),
                ]),
                _c("li", [_vm._v("Known Bugs")]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _c("div", [
      _vm._v(
        "Thank you for helping keep MyEtherWallet and our community safe!"
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }